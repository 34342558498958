import React from "react";

function AmmoniteSessionTab({ sent, visits }) {
  let colour = process.env.REACT_APP_REPORT_UNSENT_COLOR;
  if (sent) {
    if (visits > 0) {
      colour = process.env.REACT_APP_REPORT_SENT_COLOR;
    } else {
      colour = process.env.REACT_APP_REPORT_SENT_COLOR + "80";
    }
  }
  return (
    <svg
      width="1204"
      height="113"
      viewBox="0 0 1204 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="1204"
        height="104"
      >
        <rect
          x="0.5"
          y="3.5"
          width="1202.89"
          height="103"
          rx="7.5"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="0.5"
          y="1.17163"
          width="45.6624"
          height="111.149"
          fill={colour}
          stroke="white"
        />
      </g>
    </svg>
  );
}

export default AmmoniteSessionTab;
