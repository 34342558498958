import React from "react";

function BlackQuotesLogo() {
  return (
    <svg
      width="864"
      height="174"
      viewBox="0 0 864 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M743.8 41.54C743.8 33.97 737.66 27.83 730.09 27.83C722.51 27.83 716.38 33.97 716.38 41.54C716.38 49.11 722.52 55.25 730.09 55.25C737.66 55.25 743.8 49.11 743.8 41.54Z"
        fill="#212121"
      />
      <path
        d="M295.74 74.16C287.76 74.16 283.9 77.19 283.21 83.93H262.56C263.25 70.44 272.89 59.02 295.87 59.02C315.69 59.02 326.7 66.73 326.7 88.89V108.61C326.7 118.76 329.84 119.59 335.79 119.59V133.03C327.77 133.03 325.35 133 318.34 132.97C310.45 132.94 306.61 129.35 305.36 124.27H305.08C300.54 129.5 292.28 133.91 282.92 133.91C268.06 133.91 259.8 125.52 259.8 113.68C259.8 99.64 270.12 93.17 286.64 89.87C295.72 88.08 304.67 86.7 304.67 80.78C304.69 76.64 301.79 74.16 295.74 74.16ZM304.69 105.96V97.43C301.94 98.53 298.5 99.49 293.4 100.6C284.73 102.52 281.84 106.1 281.84 110.51C281.84 116.29 285.28 118.77 290.65 118.77C300.14 118.76 304.69 112.71 304.69 105.96Z"
        fill="#212121"
      />
      <path
        d="M442.44 84.07V133.02H420.14V91.1C420.14 80.5 417.94 76.92 410.09 76.92C403.07 76.92 398.66 81.19 398.66 90.96V133.03H376.64V91.1C376.64 80.5 374.44 76.92 366.59 76.92C359.71 76.92 355.16 81.19 355.16 90.96V133.03H333.14V61.23H355.16V71.41H355.44C359.16 64.94 366.86 59.02 377.46 59.02C386.68 59.02 393.15 64.11 395.9 71.41H396.18C402.24 64.39 408.43 59.02 419.85 59.02C434.04 59.02 442.44 67.97 442.44 84.07Z"
        fill="#212121"
      />
      <path
        d="M558.48 84.07V133.02H536.18V91.1C536.18 80.5 533.98 76.92 526.13 76.92C519.11 76.92 514.7 81.19 514.7 90.96V133.03H492.68V91.1C492.68 80.5 490.48 76.92 482.63 76.92C475.75 76.92 471.2 81.19 471.2 90.96V133.03H449.18V61.23H471.2V71.41H471.48C475.2 64.94 482.91 59.02 493.5 59.02C502.72 59.02 509.19 64.11 511.94 71.41H512.22C518.28 64.39 524.47 59.02 535.89 59.02C550.09 59.02 558.48 67.97 558.48 84.07Z"
        fill="#212121"
      />
      <path
        d="M601.06 133.9C577.52 133.9 563.34 118.35 563.34 96.46C563.34 74.57 577.52 59.02 601.06 59.02C624.46 59.02 638.64 74.57 638.64 96.46C638.64 118.35 624.46 133.9 601.06 133.9ZM601.06 117.38C610.7 117.38 616.61 110.09 616.61 96.46C616.61 82.83 610.69 75.54 601.06 75.54C591.29 75.54 585.37 82.83 585.37 96.46C585.37 110.09 591.29 117.38 601.06 117.38Z"
        fill="#212121"
      />
      <path
        d="M712.33 84.07V132.94H690.3V91.1C690.3 80.5 686.59 76.92 679.29 76.92C670.9 76.92 666.08 81.19 666.08 90.96V132.94H644.06V61.22H666.08V71.4H666.35C670.07 64.93 676.95 59.01 689.48 59.01C703.11 59.02 712.33 67.97 712.33 84.07Z"
        fill="#212121"
      />
      <path d="M741.09 61.22H719.07V132.94H741.09V61.22Z" fill="#212121" />
      <path
        d="M840.39 110.36H862.28C858.43 124.54 847.42 133.9 828.28 133.9C804.88 133.9 790.7 118.48 790.7 96.46C790.7 74.99 804.6 59.02 827.04 59.02C851.13 59.02 863.38 76.64 863.38 102.24H812.72C812.72 112.15 820.01 117.38 827.86 117.38C835.44 117.39 839.01 113.81 840.39 110.36ZM812.72 88.89H841.35C841.35 79.12 835.43 74.16 827.03 74.16C818.64 74.16 812.72 79.12 812.72 88.89Z"
        fill="#212121"
      />
      <path
        d="M776.34 77.74V106.92C776.34 114.9 779.23 114.9 788.59 114.9V131.42C784.6 132.24 781.16 132.93 774.96 132.93C758.85 132.93 754.31 124.39 754.31 108.57V77.73H741.1V61.21H754.32V41.53H776.34V61.21H788.59V77.73H776.34V77.74Z"
        fill="#212121"
      />
      <path
        d="M754.3 47.51C754.3 55.08 748.17 61.22 740.6 61.22H754.31L754.3 47.51Z"
        fill="#212121"
      />
      <path
        d="M39.98 41.9L29.78 2.38C35.04 1.02 40.56 0.309998 46.24 0.309998C82.6 0.309998 112.08 29.79 112.08 66.15C112.08 101.73 83.85 130.7 48.57 131.93C47.8 131.97 47.02 131.99 46.24 131.99C21.24 131.99 0.970001 111.73 0.970001 86.73C0.980001 63.85 17.95 44.95 39.98 41.9ZM155.2 131.55L145 171.06C150.26 172.41 155.78 173.13 161.46 173.13C197.82 173.13 227.3 143.66 227.3 107.29C227.3 71.71 199.07 42.74 163.79 41.51C163.02 41.47 162.24 41.45 161.46 41.45C136.46 41.45 116.2 61.71 116.2 86.71C116.19 109.6 133.16 128.5 155.2 131.55Z"
        fill="#242424"
      />
    </svg>
  );
}

export default BlackQuotesLogo;
