//React
import React, { useRef, useEffect } from "react";

//external libraries

//components
import "./aboutAmmonite.css";
import NewAmmoniteLoginLogo from "../../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";
import FideliusLogo from "../../../Assets/SVG/FideliusLogo/FideliusLogo";

//assets

function AboutAmmonite({ advisor }) {
  const aboutAmmoniteRef = useRef();

  useEffect(() => {}, [aboutAmmoniteRef]);

  return (
    <div ref={aboutAmmoniteRef} className="aboutAmmoniteCard">
      <div className="aboutAmmoniteLogoContainer">
        <FideliusLogo />
      </div>
      <div className="aboutAmmoniteParagraphFirst">About us</div>
      <div className="aboutAmmoniteParagraph">
        <div className="bulletPoint">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="5"
              cy="5"
              r="4"
              transform="rotate(-180 5 5)"
              fill="white"
              stroke="#BF802F"
              strokeWidth="2"
            />
          </svg>
        </div>
        We are a “goals based” financial planning business, giving you a
        personal service with advice designed around your goals{" "}
      </div>
      <div className="aboutAmmoniteParagraph">
        <div className="bulletPoint">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="5"
              cy="5"
              r="4"
              transform="rotate(-180 5 5)"
              fill="white"
              stroke="#BF802F"
              strokeWidth="2"
            />
          </svg>
        </div>
        We use technology to improve the customer experience, and to reduce
        costs for our clients{" "}
      </div>
      <div className="aboutAmmoniteParagraph">
        <div className="bulletPoint">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="5"
              cy="5"
              r="4"
              transform="rotate(-180 5 5)"
              fill="white"
              stroke="#BF802F"
              strokeWidth="2"
            />
          </svg>
        </div>
        We use ETFs (Exchange Traded Funds) so clients don’t pay more than they
        need to{" "}
      </div>
      <div className="aboutAmmoniteParagraph">
        We always put our customer’s priorities at the centre of everything we
        do, and we offer a personal service at outset and ongoing. If you seek
        testimonials before using us, please just ask. We’ve built up our
        reputation by “word of mouth” so if you do decide to partner with us for
        your wealth journey we appreciate the odd referral – thanks!
      </div>
    </div>
  );
}

export default AboutAmmonite;
