export const backColour = { main: "#7A7A7A" };
export const nextColour = { main: "#BF802F" };
export const confirmColour = { main: "#39B54A" };
export const textPrimaryColor = { main: "#0C3957" };
export const textSecondaryColor = { main: "#0C3957" };
export const secondaryMain = { main: "#3BB9C4" };
export const getEnvironmentURL = {
  test: "https://ammonite-testing.web.app",
  ammonite: "https://goal-mapper.com/",
  tpo: "tpourl",
};
