// Core React dependencies
import React, { useEffect } from "react";

// Component imports
import AmmoniteButton from "../../components/Button/button";
import NewAmmoniteLoginLogo from "../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";
import FideliusLogo from "../../Assets/SVG/FideliusLogo/FideliusLogo";

// Styling
import "./chooseRoute.css";

import { isIOS } from "react-device-detect";

function ChooseRoute() {
  useEffect(() => {
    if (isIOS) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="loginContainer routeLoginContainer">
      <div className="routeLogo">
        <FideliusLogo />
      </div>
      <div className="routeButtonsContainer">
        <div className="routeButtons">
          <div className="chooseRouteButtonContainer">
            <AmmoniteButton
              buttonText={"Goal mapper"}
              fontWeight="bold"
              fontSize="20px"
              borderColor="#247BA0"
              color="#FFFFFF"
              buttonLink="/about"
              width={245}
              height={69}
              backgroundColor="#247BA0"
              borderRadius="40px"
            />
          </div>
          <div className="chooseRouteButtonContainer">
            <AmmoniteButton
              buttonText={"Dashboard"}
              fontWeight="bold"
              fontSize="20px"
              borderColor="#373986"
              backgroundColor="#373986"
              color="#FFFFFF"
              buttonLink="/dashboard"
              width={245}
              height={69}
              borderRadius="40px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseRoute;
