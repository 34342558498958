import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// Component imports
import AmmoniteSBRPDFHeader from "../../../Assets/SVG/SBReportPDF/SBReportHeader/SBRPDFHeader";
import NewAmmoniteSBRPDFHeader from "../../../Assets/SVG/SBReportPDF/SBReportHeader/NewSBRPDFHeader";
import FideliusBanner from "../../../Assets/SVG/FideliusLogo/FideliusBanner";
import AmmoniteProtectedIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Protected/Protected";
import AmmonitePropertyIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Property/Property";
import AmmoniteWillIcon from "../../../Assets/SVG/SBReportFinancialHealthIcons/Will/Will";
import AmmoniteGoalRecommendation from "../../../components/suitabilityReportComponents/goalRecommendation/goalRecommendation";
import AmmoniteReporGoalCard from "../../../containers/reportContainers/ReportGoalCard/reportGoalCard";
import AmmoniteInvestmentSummary from "../../../components/suitabilityReportComponents/investmentSummary/investmentSummary";
import AmmoniteInvestmentGoalRiskLevels from "../../../components/suitabilityReportComponents/investmentGoalRiskLevels/investmentGoalRiskLevels";
import AmmoniteInvestmentAfterRecommendations from "../../../components/suitabilityReportComponents/investmentAfterRecommendations/investmentAfterRecommendations";
import AmmoniteCharge from "../../../components/suitabilityReportComponents/charge/charge";
import AmmoniteExistingPension from "../../../components/suitabilityReportComponents/existingPension/existingPension";
import { riskMatrix } from "../../../Utils/data/riskMatrix";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import AmmoniteCreateDoc from "../../../Assets/SVG/SBContextBarIcons/CreateDoc/createDoc";
import docxCreator from "../../../Utils/docx/docxCreator";

import {
  getSignature,
  getMortgageTerm,
  getLifeCover,
  getRetirementDate,
  getFundProviders,
  checkInFundProviders,
  getWhosePensions,
  areTherePensionContributions,
  areThereOtherInvestmentContributions,
  getIsISARetained,
  getIsPensionRetained,
  getCapitalisedType,
  getInvestmentSolutionsGroupedByGoals,
  getInvestmentSolutionsGroupedByGoalsRisk,
  getAllInvestmentSolutionsDeduped,
  getWrapperContributionAmount,
  getContributionTypeExists,
  getGoalImportance,
  getGoalRankedByImportance,
  getIsNotEveryGoalFocused,
  getDoesGoalFocusExist,
  getCapacityForLoss,
  getHasGoalBeenAllocated,
  getInvestmentSolutionsByGoal,
  getGoalRiskProfile,
  getInvestmentHeldIn,
  getCustomerAge,
  getEmploymentSituation,
  getNoChildren,
  budgetAndAffordabilityValues,
  getDeficitSurplus,
  getCustomerAttitudeToRisk,
  getRiskMatrix,
  getCapacityForLossStatement,
  getAnyEmployerContributions,
} from "../../../Utils/data/suitabilityReportDerivedData";
import {
  getAmount,
  getTotalImplementationChargeOnPot,
  getTotalPortfolioValue,
  getTotalImplementationCharge,
  getTotalWeightedCharge,
  getTotalPoundCharge,
  getISAGIAPensionCharges,
  getPensionCharges,
  getISAGIACharges,
  getRecommendedPolicy,
} from "../../../Utils/data/suitabilityReportCharges";
import { saveContextToFirebase } from "../../../Utils/data/saveSuitabilityContext";
import { setPreviewed } from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./suitabilityReportPDF.css";

function SuitabilityReportPDF() {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [dateNow, setDateNow] = useState(Date.now());

  const saveHandler = async () => {
    await saveContextToFirebase(state).then(() => console.log("SAVED"));
  };

  const hasPreviewed = () => {
    dispatch({ type: setPreviewed, payload: true });
    saveHandler();
  };

  const getSolutionsGroupByGoalRiskIfExists = () => {
    if (
      state.newPensionsAdvice ||
      state.newISAsAdvice ||
      (state.newGIAsAdvice && state.customerGoals && state.clientAttitudeToRisk)
    ) {
      return getInvestmentSolutionsGroupedByGoalsRisk(
        state.newPensionsAdvice,
        state.newISAsAdvice,
        state.newGIAsAdvice,
        state.customerGoals,
        state.clientAttitudeToRisk
      );
    } else {
      return [];
    }
  };

  const createNewDocX = async () => {
    try {
      docxCreator(state, `${state.suitabilityReportID}.docx`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    hasPreviewed();
  }, [state]);

  return (
    <div className="sbPDF">
      <div className="sbPDFPage">
        <div className="sbPDFHeader">
          <FideliusBanner />
        </div>
        <div className="docXButton" onClick={() => createNewDocX()}>
          <AmmoniteCreateDoc />
        </div>
        <div className="sbPDFTitle">
          <div className="topLine">FINANCIAL PLAN AND RECOMMENDATIONS</div>
          <div className="middleLine">for</div>
          <div className="bottomLine">
            {state.customerInfo.customers.customer1.customerFirstName.toUpperCase()}{" "}
            {state.customerInfo.customers.customer1.customerLastName.toUpperCase()}
            {state.customerInfo.couple && " AND "}{" "}
            {state.customerInfo.couple &&
              state.customerInfo.customers.customer2.customerFirstName.toUpperCase()}{" "}
            {state.customerInfo.couple &&
              state.customerInfo.customers.customer2.customerLastName.toUpperCase()}
          </div>
          {!status !== "success" && (
            <div className="sessionDate">
              {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
              {moment(dateNow).format("YYYY")}
            </div>
          )}
          <div className="sbrTitleLine">
            <svg
              width="1152"
              height="2"
              viewBox="0 0 1152 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="1" x2="1152" y2="1" stroke="#BF802F" strokeWidth="2" />
            </svg>
          </div>
        </div>
        <div className="sbrBody">
          {/* INTRODUCTION */}
          <div className="sbrSubheading">Introduction</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            {state.customerInfo.customers.customer1.customerFirstName}
            {state.customerInfo.couple
              ? " and " +
                state.customerInfo.customers.customer2.customerFirstName
              : ""}
            , we recently took the time to establish your financial goals. Based
            on these goals and your financial situation, we’ve put together our
            financial plan and recommendations for you. These are designed to
            make sure your hard earned money is working as hard for you as
            possible, and empower you to feel more in control of your financial
            planning.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            It’s important for you to read this report carefully, together with
            all the supplementary information we’ve provided (inluding more
            details about the recommended funds, providers, and wrappers,
            together with illustrations).
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            If you’re happy with our recommendations, and want to proceed,
            please sign the forms that we’ve provided, or drop us an email at
            adviser-hub@ammonitewealth.com and we can walk you through the
            steps.
          </div>
          <div className="sbrBlueSubSubHeading">Timing</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            Once we’ve received your signed forms, we’ll implement our
            recommendations.
          </div>
          {/* RECOMMENDATIONS */}
          <div className="sbrSubheading">Recommendations summary</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We’ve based our recommendations for you on your financial goals, to
            give you the best chance of reaching them.
          </div>
          {riskMatrix && (
            <div>
              {state.newPensionsAdvice &&
                state.newISAsAdvice &&
                state.newGIAsAdvice &&
                state.newCashAdvice &&
                state.customerGoals.map((goal, index) => (
                  <div className="goalRecommendationOuterContainer">
                    <AmmoniteGoalRecommendation
                      goalAllocated={getHasGoalBeenAllocated(
                        goal,
                        state.newPensionsAdvice,
                        state.newISAsAdvice,
                        state.newGIAsAdvice,
                        state.newCashAdvice
                      )}
                      goalType={goal.goalType}
                      goalName={goal.goalName}
                      goalAmount={goal.goalAmount ? goal.goalAmount : 0}
                      goalAmountUnderAdvice={(
                        Math.round(
                          getAmount(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice,
                            state.newCashAdvice,
                            index,
                            true
                          ) * 100
                        ) / 100
                      ).toFixed()}
                      goalMonthlyContributions={(
                        Math.round(
                          getAmount(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice,
                            state.newCashAdvice,
                            index,
                            false
                          ) * 100
                        ) / 100
                      ).toFixed()}
                      goalInvestmentPlatform={getInvestmentSolutionsByGoal(
                        state.newPensionsAdvice,
                        state.newISAsAdvice,
                        state.newGIAsAdvice,
                        index,
                        "provider",
                        state.customerGoals,
                        state.clientAttitudeToRisk
                      )}
                      goalInvestmentSolution={getInvestmentSolutionsByGoal(
                        state.newPensionsAdvice,
                        state.newISAsAdvice,
                        state.newGIAsAdvice,
                        index,
                        "investmentSolution",
                        state.customerGoals,
                        state.clientAttitudeToRisk
                      )}
                      goalRiskProfile={getGoalRiskProfile(
                        goal.goalTimeHorizon,
                        state.clientAttitudeToRisk
                      )}
                      goalHeldIn={getInvestmentHeldIn(
                        state.newPensionsAdvice,
                        state.newISAsAdvice,
                        state.newGIAsAdvice,
                        state.newCashAdvice,
                        index
                      )}
                      goalFocused={goal.focused}
                    />
                  </div>
                ))}
            </div>
          )}

          {/* ONGOING SERVICE */}
          <div className="sbrSubheading">Our ongoing service</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We keep a close eye on our recommended platforms and investments, to
            make sure that they’re doing a good job for our clients. We compare
            them to the whole market, looking at things like performance,
            charges and volatility. We are always monitoring the market for
            enhanced products, so should we recommend any changes we’ll let you
            know.{" "}
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We also stay on top of changes in regulation and taxation, which
            might have an impact on your financial planning. We send regular
            updates to our clients alerting them to things that might be
            important for them.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            Our adviser hub is available at any time (subject to working hours)
            for you to contact. You can get in touch via email, either to your
            designated qualified adviser, or into the hub at
            adviser-hub@ammonitewealth.com. You can also chat with us using the
            chatbox on our website.{" "}
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            We’ll also be in touch during the year, at important times for
            financial planning - like before the end of the tax year.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            We’ll formally review your situation annually. Every year we’ll get
            in touch to check that your goals are still current, and discuss how
            you’re progressing towards your goals. We’ll also use that time to
            find out from you if there have been any changes to your
            circumstances that might impact your financial planning, and see
            whether your attitude to risk has changed.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            We’ll also provide you with a login that you can use see your
            portfolio online.
          </div>

          {/* CURRENT SITUATION */}
          <div className="sbrSubheading">Your current situation</div>
          {!status !== "success" &&
            state.customerInfo.customers.customer1.customerDoB && (
              <div className="recommendationsParagraph recommendationsParagraph1">
                {state.customerInfo.customers.customer1.customerFirstName} you
                are aged{" "}
                {getCustomerAge(
                  state.customerInfo["customers"]["customer1"]["customerDoB"]
                )}
                . You are{" "}
                {getEmploymentSituation(
                  state.customerInfo.customers.customer1.customerWorkSituation
                )}
                .
              </div>
            )}
          {!status !== "success" && state.customerInfo.couple && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              {state.customerInfo.customers.customer2.customerFirstName} you are
              aged{" "}
              {getCustomerAge(
                state.customerInfo["customers"]["customer2"]["customerDoB"]
              )}
              . You are{" "}
              {getEmploymentSituation(
                state.customerInfo.customers.customer2.customerWorkSituation
              )}
              .
            </div>
          )}
          {!status !== "success" && state.customerInfo.children.length > 0 && (
            <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
              You have {getNoChildren(state.customerInfo.children.length)}.
              {state.customerInfo.children.map((child) => (
                <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
                  &nbsp;{child.name} is {child.age}
                  {child.financiallyDependent ? (
                    <div className="recommendationsParagraph recommendationsParagraph1 sbrChildren">
                      {" "}
                      and is financially dependent.
                    </div>
                  ) : (
                    "."
                  )}
                </div>
              ))}
            </div>
          )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              {state.additionalCustomerInformation.why}
            </div>
          )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              {state.additionalCustomerInformation.what}
            </div>
          )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              {state.additionalCustomerInformation.notes}
            </div>
          )}
          <div className="sbrSubheading">Your budget and affordability</div>
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              It's important to keep enough money in cash for emergencies, in an
              'emergency pot'. For your emergency pot, we recommend that you
              keep enough in a current account to cover expenditure for 3-6
              months. For you, this means keeping{" "}
              {budgetAndAffordabilityValues(
                "customerExpenditureRange",
                state.customerInfo
              )}{" "}
              in a current account.
            </div>
          )}
          {!status !== "success" &&
            budgetAndAffordabilityValues(
              "customerAgreedLumpSum",
              state.customerInfo
            ) > 0 && (
              <div className="recommendationsParagraph recommendationsParagraph1">
                We’ve agreed to provide recommendations for a total lump sum
                contribution of £
                {budgetAndAffordabilityValues(
                  "customerAgreedLumpSum",
                  state.customerInfo
                )}
                . This will leave you with £
                {budgetAndAffordabilityValues(
                  "customerStartingCashBalance",
                  state.customerInfo
                )}{" "}
                in accessible cash. You've confirmed that you feel this is
                affordable.
              </div>
            )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              Your total net monthly income is £
              {budgetAndAffordabilityValues(
                "customerNetMonthlyIncome",
                state.customerInfo
              )}{" "}
              per month.
            </div>
          )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              Your total monthly expenditure is £
              {budgetAndAffordabilityValues(
                "customerMonthlyExpenditure",
                state.customerInfo
              )}{" "}
              per month.
            </div>
          )}
          {!status !== "success" && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              You therefore have a {getDeficitSurplus(state.customerInfo)} per
              month.
            </div>
          )}
          {!status !== "success" &&
            (state.customerInfo.customers.customer1
              .customerAgreedMonthlyBudget > 0 ||
              state.customerInfo.customers.customer2
                .customerAgreedMonthlyBudget > 0) && (
              <div className="recommendationsParagraph recommendationsParagraph1">
                We have agreed a budget of £
                {budgetAndAffordabilityValues(
                  "customerAgreedMonthlyBudget",
                  state.customerInfo
                )}{" "}
                per month, towards your financial goals. You've confirmed that
                this is affordable for now and for the forseeable future, but if
                this changes please let us know.
              </div>
            )}
          <div className="sbrSubheading">Risk tolerance and capacity</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            Each of your goals is different, and because of that we make our
            investment recommendations on a goal-by-goal basis. To do this, we
            look at the timeframe for each goal, since you’ll be able to sustain
            more ‘ups and downs’ for longer term goals than shorter term goals.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We also use what’s known as your ‘risk tolerance’ and ‘capacity for
            risk’ to make our recommendations. ‘Risk tolerance’ means how you
            feel about risk, and how comfortable you are with fluctuations in
            the value of your investments. ‘Risk capacity’ is determined by your
            financial situation, and how much risk you can afford to take.
            Things like whether you’re still earning, the level of your income /
            savings compared to your expenditure, and your level of debt will
            have an impact on your ‘risk capacity’.
          </div>
          <div className="sbrSubheading">Your risk tolerance</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            You completed our online risk tolerance questionnaire, and based on
            your answers your risk tolerance is as follows:
          </div>
          {!status !== "success" && (
            <div className="sbrBlueSubSubHeading">
              {getCustomerAttitudeToRisk(state.clientAttitudeToRisk)} risk
            </div>
          )}
          {!status !== "success" && (
            <div className="riskMatrix">
              {getRiskMatrix(false, state.clientAttitudeToRisk)}
            </div>
          )}
          <div className="sbrBlueSubSubHeading">Your comments</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We discussed your risk tolerance, and you confirmed that{" "}
            {getCustomerAttitudeToRisk(state.clientAttitudeToRisk)} risk was
            appropriate.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            You said that '{state.clientAttitudeToRiskComment}'.
          </div>
          <div className="sbrSubheading">Your risk capacity</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            {state.whyRatingAppropriate}
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We therefore believe that the most appropriate risk capacity
            category for you is:
          </div>
          <div className="sbrBlueSubSubHeading">
            {getCapacityForLoss(state.clientCapacityForLoss)}
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            {getCapacityForLossStatement(state.clientCapacityForLoss)}
          </div>
          <div className="sbrBlueSubSubHeading">Your comments</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We discussed your risk capacity, and you confirmed that{" "}
            {getCapacityForLoss(state.clientCapacityForLoss).toLowerCase()} was
            appropriate.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            You said that {state.clientCapacityForLossComment}
          </div>
          <div className="sbrSubheading">
            Your financial plan and recommendations
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We’ve based our recommendations for you on your financial goals, to
            give you the best chance of reaching them.
          </div>
          <div className="sbrSubheading">Your goals</div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We established that you have the following financial goals:
          </div>
          <div className="sbrGoals">
            {state.customerGoals.slice(0, 3).map((goal) => (
              <div>
                <AmmoniteReporGoalCard
                  goalType={goal.goalType}
                  goalName={goal.goalName}
                  amountRequiredToday={goal.amountRequiredToday}
                  goalAmount={goal.goalAmount}
                  customer1DoB={
                    state.customerInfo.customers.customer1.customerDoB
                  }
                  goalTimeHorizon={goal.goalTimeHorizon}
                />
              </div>
            ))}
          </div>
          <div className="sbrGoals">
            {state.customerGoals.slice(3, 6).map((goal) => (
              <div>
                <AmmoniteReporGoalCard
                  goalType={goal.goalType}
                  goalName={goal.goalName}
                  amountRequiredToday={goal.amountRequiredToday}
                  goalAmount={goal.goalAmount}
                  goalTimeHorizon={goal.goalTimeHorizon}
                />
              </div>
            ))}
          </div>
          <div className="sbrSubheading">Goal focus for now</div>
          {state.customerGoals &&
            getIsNotEveryGoalFocused(state.customerGoals) &&
            state.customerGoals.length > 1 && (
              <div className="recommendationsParagraph recommendationsParagraph1">
                We think it's important to get you on your way towards your most
                important goals first. Because of that, we’ve focused on these
                goals in this report. Once you’re on your way towards these
                goals, we’ll help you on your way to achieving your other goals
                too.
              </div>
            )}
          <div className="sbrBlueSubSubHeading">
            Retirement Spending - Primary
          </div>
          {!status !== "success" && state.customerGoals[0] && (
            <div className="recommendationsParagraph recommendationsParagraph1">
              You're targeting annual spending in retirement of £
              {state.annualRetirementSpending
                ? state.annualRetirementSpending
                : 0}
              . That means you might need a retirement pot of around £
              {state.customerGoals[0].goalAmount
                ? state.customerGoals[0].goalAmount
                : 0}{" "}
              in tomorrow's money (i.e. after inflation), assuming a withdrawal
              rate of 3.5% and inflation at 2%.
              <div className="recommendationsParagraph recommendationsParagraph1">
                {state.customerGoals[0].softFacts
                  ? state.customerGoals[0].softFacts
                  : ""}
              </div>
            </div>
          )}
          {!status !== "success" &&
            getGoalRankedByImportance(state.customerGoals).map((goal) => (
              <div>
                {goal.focused === true && (
                  <div>
                    <div className="sbrBlueSubSubHeading">
                      {goal.goalName} -{" "}
                      {getGoalImportance(parseInt(goal.goalImportance))}
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {goal.softFacts ? goal.softFacts : ""}
                    </div>
                  </div>
                )}
              </div>
            ))}
          {!status !== "success" &&
            getDoesGoalFocusExist(state.customerGoals) &&
            state.customerGoals &&
            getIsNotEveryGoalFocused(state.customerGoals) &&
            getGoalRankedByImportance(state.customerGoals)
              .filter(
                (obj) => obj.focused === false || obj.focused === undefined
              )
              .map((goal, index) => (
                <div>
                  {index === 0 && (
                    <div className="sbrSubheading">Goal focus for later</div>
                  )}
                  <div>
                    <div className="sbrBlueSubSubHeading">
                      {goal.goalName} -{" "}
                      {getGoalImportance(parseInt(goal.goalImportance))}
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      {goal.softFacts ? goal.softFacts : ""}
                    </div>
                  </div>
                </div>
              ))}
          <div className="sbrSubheading">
            How we recommend you reach your goals
          </div>
          {!status !== "success" &&
            getContributionTypeExists(
              "getIsInvestmentLumpSum",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ) && (
              <div>
                <div className="sbrBlueSubSubHeading recommendationsParagraph">
                  Invest for long term goals
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Current low interest rates mean that cash holdings may lose
                  value against inflation over the long term.
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  We think its important to benefit from investment growth, to
                  grow your pots and help you reach your goals. It’s also
                  important to put your money in the right ‘tax wrapper’, so
                  that you can benefit from tax reliefs available. We recommend
                  the following lump sum contributions:
                </div>
              </div>
            )}
          {!status !== "success" &&
            getContributionTypeExists(
              "getInvestmentsWithLumpSum",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ).length > 0 &&
            getContributionTypeExists(
              "getInvestmentsWithLumpSum",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ).map((investment) => (
              <div className="investmentSummaryRow">
                <AmmoniteInvestmentSummary
                  who={
                    investment.owner === "customer2"
                      ? state.customerInfo.customers.customer2.customerFirstName
                      : state.customerInfo.customers.customer1.customerFirstName
                  }
                  wrapper={
                    investment.type.charAt(0).toUpperCase() +
                    investment.type.slice(1)
                  }
                  contributionAmount={getWrapperContributionAmount(
                    investment,
                    "LumpSum"
                  )}
                  isPensionRecommendation={true}
                  isAdviceSummary={true}
                />
              </div>
            ))}
          {!status !== "success" &&
            getContributionTypeExists(
              "getIsMonthly",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ) && (
              <div>
                <div className="sbrBlueSubSubHeading recommendationsParagraph">
                  Start making smart contributions
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  We’ve used your agreed budget to make our recommendations for
                  your regular monthly contributions. Setting up monthly
                  contributions is a great start. By contributing on a regular
                  basis you can benefit from what’s called ‘pound cost
                  averaging’, which means that you’ll benefit as investments go
                  up and down (since when investments go down you’ll be buying
                  at a lower cost). This can really help with long term
                  investing.
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  We recommend the following monthly contributions:
                </div>
              </div>
            )}
          {!status !== "success" &&
            getContributionTypeExists(
              "getInvestmentsWithMonthly",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ).length > 0 &&
            getContributionTypeExists(
              "getInvestmentsWithMonthly",
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.newCashAdvice
            ).map((investment) => (
              <div className="investmentSummaryRow">
                <AmmoniteInvestmentSummary
                  who={
                    investment.owner === "customer2"
                      ? state.customerInfo.customers.customer2.customerFirstName
                      : state.customerInfo.customers.customer1.customerFirstName
                  }
                  wrapper={
                    investment.type.charAt(0).toUpperCase() +
                    investment.type.slice(1)
                  }
                  contributionAmount={getWrapperContributionAmount(
                    investment,
                    "Monthly"
                  )}
                  isPensionRecommendation={true}
                  isAdviceSummary={true}
                />
              </div>
            ))}

          {!status !== "success" && state.pensions.length > 0 && (
            <div>
              <div className="sbrBlueSubSubHeading recommendationsParagraph">
                Take control of your pensions
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                Pensions are the most powerful tool to plan for your retirement.
                It’s really important to make sure that your pensions are
                working as hard for you as possible, and that when you come to
                retirement you’ll be able to use them in the way you want to.
                Some pensions are restrictive in the way you can draw money from
                them, the investments might not be in line with your risk
                profile, they may have high charges etc. We’ve looked at all of
                the features of those pensions you asked us to research, and
                recommend the following (our full analysis of the pensions is
                detailed later in this report).{" "}
              </div>
              <div className="investmentSummaryRow">
                {!status !== "success" &&
                  state.pensions.map((pension) => {
                    return (
                      <AmmoniteInvestmentSummary
                        who={
                          pension.owner === "customer1"
                            ? state.customerInfo.customers.customer1
                                .customerFirstName
                            : state.customerInfo.customers.customer2
                                .customerFirstName
                        }
                        provider={pension.provider}
                        policyNumber={pension.policyNumber}
                        recommendation={pension.transferPension}
                        isPensionRecommendation={true}
                        isAdviceSummary={false}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          {!status !== "success" && state.investments.length > 0 && (
            <div>
              <div className="sbrBlueSubSubHeading recommendationsParagraph">
                Focus on your investments
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                It's important to make sure that you invest in line with your
                goals, to give you the best chance of reaching them. We've
                looked at your existing investment holdings, and recommend the
                following:
              </div>
              <div className="investmentSummaryRow">
                {!status !== "success" &&
                  state.investments.map((investment) => {
                    return (
                      <AmmoniteInvestmentSummary
                        who={
                          investment.owner === "customer1"
                            ? state.customerInfo.customers.customer1
                                .customerFirstName
                            : state.customerInfo.customers.customer2
                                .customerFirstName
                        }
                        provider={investment.provider}
                        policyNumber={investment.policyNumber}
                        recommendation={{
                          transfer: investment.transferISA,
                          sell: investment.sell,
                        }}
                        isInvestmentRecommendation={true}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          <div className="sbrBlueSubSubHeading recommendationsParagraph">
            Use our recommended provider and investment solution
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We’ve looked at the whole of the market, so that you don’t have to.
            It’s important to make sure that your providers and investment
            solutions work for you. That means choosing providers that have good
            administration and are cost-efficient, with user-friendly online
            tools and great reporting. The investment solutions need to be right
            for your goals and risk profile, with good diversification and low
            charges.
          </div>
          {!status !== "success" &&
            (state.newPensionsAdvice.length > 0 ||
              state.newISAsAdvice.length > 0 ||
              state.newGIAsAdvice.length > 0 ||
              state.newCashAdvice.length > 0) && (
              <div className="recommendationsParagraph recommendationsParagraph1">
                Based on these considerations, we’ve chosen the following
                solutions for you:
              </div>
            )}
          {!status !== "success" &&
            state.newPensionsAdvice &&
            state.newISAsAdvice &&
            state.newGIAsAdvice &&
            getAllInvestmentSolutionsDeduped(
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.customerGoals,
              state.clientAttitudeToRisk
            ).map((solution) => (
              <div>
                <div className="investmentSummaryRow">
                  <AmmoniteInvestmentSummary
                    provider={solution.provider}
                    investmentSolution={solution.investmentSolution}
                    isAdviceSummary
                  />
                </div>
              </div>
            ))}
          <div className="recommendationsParagraph recommendationsParagraph1">
            For further details on why we recommend these solutions, please see
            the relevant sections below.
          </div>
          <div className="sbrBlueSubSubHeading recommendationsParagraph">
            Will I reach my goals?
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            We can’t guarantee that you’ll reach your goals, but our
            recommendations are designed to put you on the best path to getting
            there. If you’re able to increase your contributions in the future,
            that might help you reach your goals faster.
          </div>
          <div className="sbrSubheading">
            Your portfolio after our recommendations
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1">
            After you implement our recommendations, your portfolio will be made
            up of the following policies (excluding any policies you didn’t ask
            us to research for you, since they fall outside our advice):
          </div>
          {/* PENSIONS */}

          {!status !== "success" &&
            state.newPensionsAdvice &&
            state.newISAsAdvice &&
            state.newGIAsAdvice &&
            getInvestmentSolutionsGroupedByGoals(
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.customerGoals,
              state.clientAttitudeToRisk
            ).map((solution) => (
              <div className="investmentSummaryRow">
                <AmmoniteInvestmentAfterRecommendations
                  owner={
                    solution.owner === "customer2"
                      ? state.customerInfo.customers.customer2.customerFirstName
                      : state.customerInfo.customers.customer1.customerFirstName
                  }
                  wrapper={getCapitalisedType(solution.type)}
                  provider={solution.provider}
                  investments={solution.investmentSolutions}
                  totalInitialAmount={solution.totalInitialAmount}
                  monthlyContributions={solution.monthlyContributions}
                  goalsAttributed={solution.goalsAttributedProperties}
                />
              </div>
            ))}
          {!status !== "success" &&
            state.pensions &&
            getIsPensionRetained(state.pensions).map((solution) => (
              <div className="investmentSummaryRow">
                <AmmoniteInvestmentAfterRecommendations
                  owner={
                    solution.owner === "customer2"
                      ? state.customerInfo.customers.customer2.customerFirstName
                      : state.customerInfo.customers.customer1.customerFirstName
                  }
                  wrapper={solution.planType}
                  provider={solution.provider}
                  investments={solution.investmentSolution}
                  totalInitialAmount={solution.transferValue}
                  monthlyContributions={solution.contributions}
                />
              </div>
            ))}
          {!status !== "success" &&
            state.investments &&
            getIsISARetained(state.investments).map((solution) => (
              <div className="investmentSummaryRow">
                <AmmoniteInvestmentAfterRecommendations
                  owner={
                    solution.owner === "customer2"
                      ? state.customerInfo.customers.customer2.customerFirstName
                      : state.customerInfo.customers.customer1.customerFirstName
                  }
                  wrapper={solution.planType.toUpperCase()}
                  provider={solution.provider}
                  investments={solution.investments}
                  totalInitialAmount={solution.value}
                  monthlyContributions={solution.contributions}
                />
              </div>
            ))}

          <div className="sbrSubheading">
            Why we've made these recommendations
          </div>
          {!status !== "success" &&
            (areTherePensionContributions(state.newPensionsAdvice) ||
              areThereOtherInvestmentContributions(
                state.newISAsAdvice,
                state.newGIAsAdvice
              )) && (
              <div>
                <div className="sbrBlueSubSubHeading">
                  Why these contribution amounts?
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  We discussed your budget and the amount you could invest as a
                  lump sum contribution towards your goals, and based on our
                  recommendations on this.
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  In terms of the split between the different wrappers (i.e. ISA
                  / Pension / Cash / General Investment Account), we’ve used our
                  tools to come up with the best plan for you. We take into
                  account the goal amounts that you’re trying to achieve, and
                  when you’re aiming to get there. We also take into
                  consideration your risk tolerance and time horizon for each
                  goal. Additionally, we take government tax allowances into
                  account, because we want you to be able to benefit from these
                  as much as possible.
                </div>
              </div>
            )}
          {!status !== "success" &&
            areTherePensionContributions(state.newPensionsAdvice) && (
              <div>
                <div className="sbrBlueSubSubHeading">
                  Your pension contributions
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Pensions are a very tax-efficient way of planning for
                  retirement, since contributions benefit from tax relief at
                  your marginal rate of tax. Personal contributions to a
                  registered pension scheme receive basic rate tax relief at
                  source. This means for every £80 paid in, the pension fund
                  will receive an additional £20 in tax relief. Higher and
                  additional rate taxpayers can claim further tax relief through
                  their tax return.
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Tax relief on personal gross contributions is restricted to
                  the higher of £3,600 or 100% of your ‘relevant UK earnings’
                  each year and is capped at an annual allowance of £40,000
                  (unused allowances can be carried forward from the previous
                  three tax years). If your income is over £240,000, your annual
                  allowance is restricted.
                </div>
                <div className="recommendationsParagraph recommendationsParagraph1">
                  Based on your income and contribution history, you have the
                  scope to make the recommended contributions without exceeding
                  your annual allowance.
                </div>
                {getAnyEmployerContributions(state.newPensionsAdvice) && (
                  <div className="recommendationsParagraph recommendationsParagraph1">
                    {getWhosePensions(
                      state.customerInfo,
                      state.newPensionsAdvice
                    )}
                    , we recommend that you make contributions as employer
                    contributions from your limited company. Your limited
                    company can contribute pre-taxed company income to your
                    pension. Because an employer contribution counts as an
                    allowable business expense, your company receives tax relief
                    against corporation tax, so the company could save up to 19%
                    in corporation tax.
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      Another benefit is that employers don’t have to
                      pay National Insurance on pension contributions, so by
                      contributing directly into your pension rather than paying
                      the equivalent in salary, you save up to 13.8%.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      This means that in total, your company can save up to
                      32.8% by paying money directly into your pension rather
                      than paying money in the form of a salary. 
                    </div>
                  </div>
                )}
              </div>
            )}

          {!status !== "success" && state.newPensionsAdvice.length > 0 && (
            <div>
              <div className="sbrBlueSubSubHeading recommendationsParagraph">
                Why these ‘wrappers’?
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 nonFlex">
                <strong>Pension portfolio</strong> - Not only do pensions
                benefit from tax relief on contributions, but they also benefit
                from tax-free growth within the pension. That means that the
                capital growth and dividends you may receive within the pension
                are tax-free. This makes pensions a great tax wrapper. However,
                there are rules about when you can access the money in your
                pension. Unless you’re retiring early because of ill health, the
                earliest you can currently draw money from your pension is age
                55. We’ve taken this into account in our recommendations.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                We think it’s important to use a self-invested personal pension
                rather than transferring into an employer scheme, since you have
                more control over a personal pension, and to keep consistency
                even if you were to change employment in the future. You will
                also have a wider scope of investment opportunities available to
                you, including our recommended investments.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                We’ve considered whether you should use a ‘Stakeholder pension’,
                however we’ve discounted this because our recommended pension is
                cheaper, and offers our recommended investment solution.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                We've recommended a pension that offers 'flexi access drawdown'.
                Flexi-access drawdown is a pension product that lets you access
                your pension savings whenever you need to in retirement, while
                reinvesting your remaining funds in a way that’s designed to
                provide an ongoing retirement income. Flexi-access drawdown
                allows you to withdraw as much or as little retirement income as
                you wish, while choosing how the remainder of your fund is
                invested. As part of the wide-ranging 2015 pension reforms,
                which allow those nearing retirement to gain greater control
                over their pensions, flexi-access drawdown is available from
                your 55th birthday and provides a hassle-free way to unlock cash
                from your pension. With flexi-access drawdown you can take up to
                25% of your pension tax-free, as a lump sum or in portions.
                Whether you intend to use it to supplement your income, to help
                loved ones or fulfil a lifelong dream, it can be yours to spend
                however you wish.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                Once you’ve taken your tax-free lump sum, the rest of your
                pension pot can be left invested. This offers the opportunity
                for growth, unlike an annuity which provides a fixed income.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                If you decide to draw a regular income it’s possible to manage
                the amount you want, and at your desired intervals. If you wish,
                this can be adjusted periodically to help fit your circumstances
                and can save you income tax with some clever planning.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                Should you pass away before releasing all of your pension, you
                can nominate someone to receive the remaining funds on your
                behalf - such as a relative or charity. On death before age 75,
                they will inherit the pot tax-free. On death after age 75, it's
                paid out subject to their marginal rate of income tax.
              </div>
            </div>
          )}
          {!status !== "success" && state.newISAsAdvice.length > 0 && (
            <div className="recommendationsParagraph recommendationsParagraph1 ">
              <strong>ISA</strong> - Individual Savings Accounts, or ‘ISAs’, are
              a good wrapper to invest in, since investments within an ISA are
              free from income and capital gains tax. Not only that, but subject
              to restrictions on the investments themselves, you can access the
              money within your ISA without restriction. You can therefore use
              your ISA for those goals that you’ll need before retirement. The
              ISA allowance is currently £20,000 per person, per year. Our
              recommendations are based on what you’ve told us about your ISA
              contributions, and are designed to maximise your ISA contributions
              while taking into account your budget and pension contributions.
            </div>
          )}
          {!status !== "success" && state.newGIAsAdvice.length > 0 && (
            <div className="recommendationsParagraph recommendationsParagraph1 ">
              <strong>GIA</strong> - A general investment account, or 'GIA' is a
              simple way of investing more money once you've reached your annual
              ISA allowance. Unlike an ISA or pension, there are no tax
              benefits, although you can contribute as much as you wish. Like an
              ISA, you can access the money without restriction. You pay income
              tax on any income you receive from the GIA, and capital gains tax
              on any realised gain you make on your GIA. The tax paid will
              depend on your personal tax situation. For further details on the
              taxation of GIAs, please see the 'Key Features' document.
            </div>
          )}
          {!status !== "success" && state.newCashAdvice.length > 0 && (
            <div className="recommendationsParagraph recommendationsParagraph1 ">
              <strong>Cash</strong> - For shorter term goals, we recommend
              keeping money in a cash account, to protect against the investment
              risks of your money going up and down over the short time horizon.
              We don't provide advice on which cash account to use, however you
              can get details of savings rates available using the links on our
              website.
            </div>
          )}

          {!status !== "success" &&
            getFundProviders(
              state.newPensionsAdvice,
              state.newISAsAdvice,
              state.newGIAsAdvice,
              state.customerGoals,
              state.clientAttitudeToRisk
            ).map((provider) => {
              return (
                <div>
                  <div className="sbrBlueSubSubHeading">
                    Why {provider.provider}?
                  </div>
                  {provider.pParagraphs.map((para) => {
                    return (
                      <div className="recommendationsParagraph recommendationsParagraph1 ">
                        {para}
                      </div>
                    );
                  })}
                </div>
              );
            })}

          {getInvestmentSolutionsGroupedByGoals(
            state.newPensionsAdvice,
            state.newISAsAdvice,
            state.newGIAsAdvice,
            state.customerGoals,
            state.clientAttitudeToRisk
          ).length > 0 && (
            <div className="sbrBlueSubSubHeading">
              How do the investments relate to my goals?
            </div>
          )}

          {!status !== "success" &&
            getSolutionsGroupByGoalRiskIfExists().map((solution) => {
              return (
                <AmmoniteInvestmentGoalRiskLevels
                  investment={solution.investmentSolution}
                  equity={solution.equity}
                  goals={solution.goalsAttributed}
                />
              );
            })}
          <div className="sbrSubheading">What are the charges?</div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            From our independent, whole of market research, we choose providers
            that offer excellent service and expertise, at low cost. We
            continuously research the market for best of breed product providers
            and monitor investment performance to optimise customer
            satisfaction.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Your initial implementation charges and ongoing costs will be taken
            from the products on an ongoing basis, so you don’t have to worry
            about paying these from your bank account. Taking charges from a
            pension is tax-efficient since it means that you’re paying from a
            policy that has benefited from tax relief.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            The charges are based on percentages. This means that if the
            investments go up or down in value, then the charges will fluctuate
            too. The charges are based on just those new policies that will be
            under our advice, and they’ll come from the investments so you don’t
            have to worry about paying these from your bank account.
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            It’s important to look at the illustrations we’ve provided to see
            what the impact of charges will be on your portfolio.{" "}
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Based on current values (including the first years’ contributions as
            appropriate), the annual charges will be:
          </div>
          {!status !== "success" && state.newISAsAdvice && state.newGIAsAdvice && (
            <div className="investmentSummaryRow">
              <div className="investmentSummaryRow">
                <AmmoniteCharge
                  chargeName="ISA / General Investment Account:"
                  paRate={getISAGIACharges(
                    state.newISAsAdvice,
                    state.newGIAsAdvice,
                    false,
                    state.customerGoals,
                    state.clientAttitudeToRisk
                  ).toFixed(2)}
                />
              </div>
              <div className="investmentSummaryRow">
                <AmmoniteCharge
                  chargeName="Pension:"
                  paRate={getPensionCharges(
                    state.newPensionsAdvice,
                    state.totalWeightedCharge,
                    state.customerGoals,
                    state.clientAttitudeToRisk
                  )}
                />
              </div>
              <div className="investmentSummaryRow">
                {getISAGIACharges(
                  state.newISAsAdvice,
                  state.newGIAsAdvice,
                  "discretionary",
                  state.customerGoals,
                  state.clientAttitudeToRisk
                ) === true && (
                  <AmmoniteCharge
                    chargeName="Discretionary Fund Management:"
                    discretionaryCharge={0.9}
                  />
                )}
              </div>
              <div className="investmentSummaryRow">
                <AmmoniteCharge
                  chargeName="Underlying Investments:"
                  paRate={getISAGIAPensionCharges(
                    state.newPensionsAdvice,
                    state.newISAsAdvice,
                    state.newGIAsAdvice,
                    false,
                    state.customerGoals,
                    state.clientAttitudeToRisk
                  )}
                />
              </div>
              <div className="investmentSummaryRow">
                <AmmoniteCharge
                  chargeName="Financial Planning and Advice:"
                  paRate={0.75}
                />
              </div>
            </div>
          )}
          <div className="investmentSummaryRow">
            <AmmoniteCharge
              chargeName="Total £ charge:"
              totalPoundCharge={getTotalPoundCharge(
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice,
                state.customerGoals,
                state.clientAttitudeToRisk
              )}
            />
          </div>
          <div className="investmentSummaryRow">
            <AmmoniteCharge
              chargeName="Total weighted charge:"
              paRate={getTotalWeightedCharge(
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice,
                state.customerGoals,
                state.clientAttitudeToRisk
              ).toFixed(2)}
            />
          </div>
          {getISAGIACharges(
            state.newISAsAdvice,
            state.newGIAsAdvice,
            "discretionary",
            state.customerGoals,
            state.clientAttitudeToRisk
          ) === true && (
            <div className="recommendationsParagraph recommendationsParagraph1 ">
              Please note that, within your Cazenove discretionary managed
              portfolio, there are additional charges for the underlying
              holdings, which will vary. For full details of the underlying
              charges, please see your investment proposal.
            </div>
          )}
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            On a long-term basis, we target the following annual growth rates
            before charges (though investments go up and down):
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Low-medium risk - circa 3.0% annualised
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Medium risk - circa 5.0% annualised
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Medium-high risk - circa 6.5% annualised
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            High risk - 8.5% annualised
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            (Please note that these returns aren’t guaranteed and will vary,
            sometimes significantly, year on year).
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            We have a one-off implementation charge, which covers the costs of
            the implementation of our recommendations and the regulatory fees.
            We take this directly from the recommended policies once they’re set
            up. Based on the initial value of your investments, this will be:
          </div>
          <div className="investmentSummaryRow">
            <AmmoniteCharge
              chargeName="Total Initial implementation charge:"
              paRate={getTotalImplementationCharge(
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice
              ).toFixed(2)}
              totalPot={getTotalPortfolioValue(
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice
              )}
              totalCharge={getTotalImplementationChargeOnPot(
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice
              )}
            />
          </div>

          {state.pensions.length > 0 && (
            <div>
              <div className="sbrSubheading">Your existing pension plans</div>
              <div className="recommendationsParagraph recommendationsParagraph1 ">
                We looked closely at your existing policies, and have done a
                full analysis which you’ll find below, including our reasons for
                recommending that you retain or transfer the policy.
              </div>
            </div>
          )}
          {!status !== "success" &&
            state.newPensionsAdvice &&
            state.pensions.map((pension, index) => (
              <AmmoniteExistingPension
                owner={
                  pension.owner === "customer2"
                    ? state.customerInfo.customers.customer2.customerFirstName
                    : state.customerInfo.customers.customer1.customerFirstName
                }
                provider={pension.provider ? pension.provider : "-"}
                policyNumber={pension.policyNumber ? pension.policyNumber : "-"}
                fundValue={pension.fundValue ? pension.fundValue : "-"}
                transferValue={
                  pension.transferValue ? pension.transferValue : "-"
                }
                retirementDate={pension.srdNrd ? pension.srdNrd : "-"}
                investmentChoice={
                  pension.investments ? pension.investments : "-"
                }
                penaltiesOnTransfer={
                  parseInt(pension.penaltiesOnTransfer)
                    ? parseInt(pension.penaltiesOnTransfer)
                    : "-"
                }
                protectedTaxFreeCash={
                  pension.protectedTaxFreeCash
                    ? pension.protectedTaxFreeCash
                    : "-"
                }
                guarantees={
                  pension.guaranteeDetails ? pension.guaranteeDetails : "-"
                }
                oorGuarantees={pension.oorGuarantees}
                recommendation={pension.transferPension ? "TRANSFER" : "RETAIN"}
                annuityPurchase={
                  pension.annuityPurchase ? pension.annuityPurchase : "-"
                }
                uFPLS={pension.uFPLS ? pension.uFPLS : "-"}
                flexiAccess={pension.flexiAccess ? pension.flexiAccess : "-"}
                drawdown={pension.drawdown ? pension.drawdown : "-"}
                charges={pension.charges ? pension.charges : "-"}
                expressionOfWish={
                  pension.expressionOfWish ? pension.expressionOfWish : "-"
                }
                recommendedCharges={
                  getRecommendedPolicy(
                    state.newPensionsAdvice,
                    pension.owner,
                    state.customerGoals,
                    state.clientAttitudeToRisk
                  )
                    ? getRecommendedPolicy(
                        state.newPensionsAdvice,
                        pension.owner,
                        state.customerGoals,
                        state.clientAttitudeToRisk
                      )
                    : "-"
                }
              />
            ))}

          <div className="sbrSubheading">
            Ways to improve your financial health
          </div>
          <div className="sbrBlueSubSubHeading">
            Make sure you’re well protected
          </div>
          <div className="financialHealthCols">
            <div className="financialHealthCol1">
              <AmmoniteProtectedIcon />
            </div>
            <div className="financialHealthCol2">
              {!status !== "success" && (
                <div className="recommendationsParagraph recommendationsParagraph1">
                  {getLifeCover(
                    state.financialCheck.lifeCover,
                    state.financialCheck.incomeProtection
                  )}
                </div>
              )}
              <div className="recommendationsParagraph recommendationsParagraph1">
                It’s important to have cover in place, to help in the events of,
                for example, a critical illness, an illness or disability that
                means you can’t work, or if you were to die - to protect you and
                your loved ones. This is particularly important if you have
                financial dependants and/or if you have a mortgage.
              </div>
              <div className="recommendationsParagraph recommendationsParagraph1">
                Let us know if you would like us to put you in touch with a
                protection specialist who can help you with this aspect of your
                financial planning.
              </div>
            </div>
          </div>
          {state.financialCheck.hasMortgage &&
            state.financialCheck.timeUntilMortgageDealEnds === 1 && (
              <div>
                <div className="sbrBlueSubSubHeading">
                  Make sure you have the best mortgage deal
                </div>
                <div className="financialHealthCols">
                  <div className="financialHealthCol1">
                    <AmmonitePropertyIcon />
                  </div>
                  <div className="financialHealthCol2">
                    {state.financialCheck.hasMortgage &&
                      state.financialCheck.timeUntilMortgageDealEnds && (
                        <div className="recommendationsParagraph recommendationsParagraph1">
                          Your mortgage deal runs out in{" "}
                          {getMortgageTerm(
                            state.financialCheck.timeUntilMortgageDealEnds
                          )}
                          .
                        </div>
                      )}
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      The impact of your mortgage rate can be huge over the
                      longer term, saving you alot of money. When your mortgage
                      deal comes up for renewal, its important to secure the
                      best deal possible.
                    </div>
                    <div className="recommendationsParagraph recommendationsParagraph1">
                      Let us know if you would like us to put you in touch with
                      a trusted mortgage broker, to help you with this.
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="sbrBlueSubSubHeading">
            Make sure you have an up to date Will and Lasting Power of Attorney
          </div>
          <div className="financialHealthCols">
            <div className="financialHealthCol1">
              <AmmoniteWillIcon />
            </div>
            <div className="financialHealthCol2">
              {state.financialCheck.will && (
                <div className="recommendationsParagraph recommendationsParagraph1 ">
                  You have a Will.
                </div>
              )}
              {!state.financialCheck.will && (
                <div className="recommendationsParagraph recommendationsParagraph1 ">
                  You don't have a Will.
                </div>
              )}
              <div className="recommendationsParagraph recommendationsParagraph1">
                It’s important to make sure that you have a Will and Lasting
                Power of Attorney in place, and that you review these regularly
                - particularly when there are changes in your financial or
                personal situation. Let us know if you would like us to put you
                in touch with someone that can help.
              </div>
            </div>
          </div>
          <div className="sbrSubheading">Thank you</div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            Thanks for taking the time to read this report!
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            We hope you have found this detailed report helpful. We pride
            ourselves on offering high quality financial advice at very
            affordable prices and look forward to making this financial plan a
            reality with you. Please drop us a line at
            adviser-hub@ammonitewealth.com or call us on 020 3434 2718 to get
            started with your financial plan.
          </div>
          {getSignature(state.adviser)}

          <div className="sbrSubheading">Risk factors</div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              The value of your shares/units and the income from them will rise
              or fall as the value of the underlying investments in which your
              money is invested changes.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              When you sell your investment you may get back less than you
              invested.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              Movements in exchange rates may cause the value of your investment
              to go up or down.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              To obtain the benefit of equity investing, a long term view is
              usually advised.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              The fund performance is not guaranteed and therefore past
              performance is no guide to future performance.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              Recommendations are based on our understanding of current tax
              legislation, which may be subject to change and review.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              The value of tax relief depends on your own financial
              circumstances and may change in the future.
            </i>
          </div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              The information given is based upon our understanding of current
              legislation and HM Revenue and Customs’ practice, both of which
              are subject to change.
            </i>
          </div>

          <div className="sbrSubheading">Cancellation rights</div>
          <div className="recommendationsParagraph recommendationsParagraph1 ">
            <i>
              Once your investment plan is set up you have a legal right to
              cancel should you change your mind, the period is generally 30
              days (which may reduce to 14 days for ISA, Unit Trust and EIS
              investments and may increase to 60 days for annuities). The amount
              you get back may be reduced by a decrease in value between making
              your initial investment and up until your investment is sold.
            </i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuitabilityReportPDF;
