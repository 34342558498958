import React from "react";
import FideliusLogoPNG from "./FideliusLogo.png";

function FideliusLogo({}) {
  return (
    <svg
      width="480"
      height="150"
      viewBox="0 0 480 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="480" height="150" fill="url(#pattern0)" />
      <image width="480" height="150" xlinkHref={FideliusLogoPNG} />
    </svg>
  );
}

export default FideliusLogo;
