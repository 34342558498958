// Core React dependencies
import React, { useRef, useState, useEffect, useCallback } from "react";

// Extra React dependencies
import { useHistory, useLocation } from "react-router-dom";

// External libraries
import "firebase/auth";
import "firebase/firestore";
import "@firebase/storage";
import Button from "@material-ui/core/Button";
import DotLoader from "react-spinners/DotLoader";
import emailjs from "emailjs-com";
import { css } from "@emotion/core";
import uuid from "react-uuid";
import { useToasts } from "react-toast-notifications";

// Component imports
import Report from "../../containers/reportContainers/Report/report";

// Utilities
import firebase from "../../Utils/firebase";
import {
  areasForAdvice,
  financialGoals,
  financialHealthCheckResults,
  importantThingsToNote,
} from "../../Utils/emailTemplates/advisorSummaryEmail";
import { annualSpending } from "../../Utils/data/annualSpending";

// Styling
import "./reportViewer.css";

function ReportViewer() {
  const history = useHistory();
  const location = useLocation();
  const reportInViewerRef = useRef();
  const isLoaded = useRef(true);
  const [reportSent, setReportSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sessionData, setSessionData] = useState(
    location.customNameData.sessionData
  );
  const [adviser, setAdviser] = useState(location.customNameData.adviser);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [templateParams, setTemplateParams] = useState();
  const [summaryTemplateParams, setSummaryTemplateParams] = useState();
  const [loadingStatus, setLoadingStatus] = useState();
  const { addToast } = useToasts();

  const updateSessionsCustomerName = firebase
    .functions()
    .httpsCallable("updateSessionsCustomerName");

  // const generateCustomerReportLink = firebase.functions().httpsCallable('generateCustomerReportLink');
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  let name = "";

  if (sessionData.customerInfo.couple === true) {
    name =
      sessionData.customerInfo.customers.customer1.customerFirstName +
      " and " +
      sessionData.customerInfo.customers.customer2.customerFirstName;
  } else {
    name = sessionData.customerInfo.customers.customer1.customerFirstName;
  }

  let adviserEmail = "";
  let adviserAvatar = "";
  let adviserFullName = "";
  if (adviser === "Rob") {
    adviserFullName = "Rob Harradine";
    adviserEmail = "robert@ammonitewealth.com";
    adviserAvatar =
      "https://firebasestorage.googleapis.com/v0/b/static_bucket-2dfa7/o/robert-avatar.png?alt=media&token=c4fa1fe1-56c7-435b-9126-53f7e120f8b0";
  } else if (adviser === "Michael") {
    adviserFullName = "Michael Hughes";
    adviserEmail = "michael@ammonitewealth.com";
    adviserAvatar =
      "https://firebasestorage.googleapis.com/v0/b/static_bucket-2dfa7/o/michael-avatar.png?alt=media&token=44891e96-c2e4-405a-9e66-cb0ddb8e8a7c";
  } else {
    adviserFullName = "Caroline Duff";
    adviserEmail = "caroline@ammonitewealth.com";
    adviserAvatar =
      "https://firebasestorage.googleapis.com/v0/b/static_bucket-2dfa7/o/caroline-avatar.png?alt=media&token=c8f71991-3850-49e8-8fef-eb54502fac45";
  }

  function sendEmail() {
    if (templateParams && summaryTemplateParams) {
      setSendingEmail(true);
      addToast("Emailing report", {
        appearance: "success2",
        autoDismiss: true,
      });
      emailjs
        .send(
          "outlook",
          process.env.REACT_APP_REPORT_ADVISER_TEMPLATE,
          summaryTemplateParams,
          "user_qSZvdg7MBAMXbZIPsDdmJ"
        )
        .then(
          (result) => {
            addToast("Summary sent", {
              appearance: "success2",
              autoDismiss: true,
            });
            setReportSent(true);
          },
          (error) => {
            addToast("Error sending summary email", {
              appearance: "error",
              autoDismiss: false,
            });
            console.log(error.text);
          }
        );
      emailjs
        .send(
          "outlook",
          process.env.REACT_APP_REPORT_CUSTOMER_TEMPLATE,
          templateParams,
          "user_qSZvdg7MBAMXbZIPsDdmJ"
        )
        .then(
          (result) => {
            addToast("Report sent", {
              appearance: "success2",
              autoDismiss: true,
            });
            setReportSent(true);
          },
          (error) => {
            addToast("Error sending report email", {
              appearance: "error",
              autoDismiss: false,
            });
            console.log(error.text);
          }
        )
        .then(
          async () =>
            await updateSessionsCustomerName({
              dataUid: sessionData.id,
              firstName:
                sessionData.customerInfo.customers.customer1.customerFirstName,
              lastName:
                sessionData.customerInfo.customers.customer1.customerLastName,
            })
        );
    }
  }

  const reportIsLoaded = useCallback(
    (sigLoaded) => {
      setReportLoaded(sigLoaded);
    },
    [reportLoaded]
  );

  const returnToDashboard = () => {
    history.push("/dashboard");
  };

  const getUniqueFilename = () => {
    return (name + uuid()).replace(/ /g, "");
  };

  const createURL = () => {
    setTemplateParams({
      to: sessionData.sendTo ? sessionData.sendTo : sessionData.customerEmail,
      customers: name,
      adviser: adviserFullName,
      adviserEmail: adviserEmail,
      adviserAvatar: adviserAvatar,
      companyBanner: process.env.REACT_APP_BANNER_IMG,
      company: process.env.REACT_APP_COMPANY_NAME,
    });
    setSummaryTemplateParams({
      to: adviserEmail,
      customers: `${sessionData.customerInfo.customers.customer1.customerLastName}`,
      adviser: adviser,
      customersFullNames: name,
      coachName: sessionData.coachName
        ? sessionData.coachName
        : "no coach data gathered",
      sessionDate: sessionData.sessionDate.seconds
        ? new Date(sessionData.sessionDate.seconds * 1000)
            .toString()
            .slice(4, 21)
        : "-",
      customer1FullName: `${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}`,
      customer1Age: Math.floor(
        (new Date() -
          new Date(
            sessionData.customerInfo.customers.customer1.customerDoB.seconds *
              1000
          ).getTime()) /
          3.15576e10
      ),
      customer2FullName:
        sessionData.customerInfo.couple === true
          ? `${sessionData.customerInfo.customers.customer2.customerFirstName} ${sessionData.customerInfo.customers.customer2.customerLastName}`
          : "-",
      customer2Age:
        sessionData.customerInfo.couple === true
          ? Math.floor(
              (new Date() -
                new Date(
                  sessionData.customerInfo.customers.customer2.customerDoB
                    .seconds * 1000
                ).getTime()) /
                3.15576e10
            )
          : "-",
      phoneNumber: sessionData.customerTelephone,
      customerEmail: sessionData.customerEmail,
      children: sessionData.customerInfo.hasChildren ? "Yes" : "No",
      retirementSpending: `£${annualSpending(
        sessionData.retirementSpending,
        sessionData.retirementComforts,
        sessionData.retirementLuxuries,
        sessionData.customerInfo.couple
      )} p.a.`,
      importantThingsToNote: importantThingsToNote(
        sessionData.likelihood,
        sessionData.anticipatedAssets,
        sessionData.areasOfInterest,
        sessionData.interestComments,
        sessionData.recommend,
        sessionData.referralDetails,
        sessionData.coachName ? sessionData.coachName : "no coach data gathered"
      ),
      customersSituation: sessionData.customerSituation
        ? sessionData.customerSituation
        : "",
      areasForAdvice: areasForAdvice(
        sessionData.financialCheck,
        sessionData.customerInfo.hasChildren,
        sessionData.customerInfo.customers.customer1.customerWorkSituation
      ),
      goals: financialGoals(sessionData.customerGoals),
      financialHealthcheckResults: financialHealthCheckResults(
        sessionData.financialCheck
      ),
      companyBanner: process.env.REACT_APP_BANNER_IMG,
      company: process.env.REACT_APP_COMPANY_NAME,
    });
  };

  useEffect(() => {
    if (reportSent) {
      firebase
        .firestore()
        .collection("sessions")
        .doc(sessionData.id)
        .update({
          reportSent: reportSent,
        })
        .then(returnToDashboard())
        .catch((err) => {
          console.log("ERROR", err);
          addToast("Error updating firebase report status", {
            appearance: "error",
            autoDismiss: false,
          });
        });
    }
    if (
      reportInViewerRef &&
      reportInViewerRef.current &&
      isLoaded.current &&
      reportLoaded
    ) {
      let node = reportInViewerRef.current;
      let reportHeight = reportInViewerRef.current.scrollHeight * 0.65;
      let reportWidth = reportInViewerRef.current.offsetWidth * 0.65;
      let imgHeight = reportHeight;
      if (reportHeight >= 14400) {
        imgHeight = reportHeight - 9100;
      }
      createURL(node, reportHeight, reportWidth, imgHeight);

      return () => {
        isLoaded.current = false;
      };
    }
  }, [reportSent, reportInViewerRef, reportLoaded, templateParams]);

  return (
    <div className="reportViewerContainer">
      {sendingEmail && (
        <div className="sendingEmailBlockInteraction">
          <DotLoader
            css={override}
            size={300}
            color={"#0c3957"}
            loading={sendingEmail}
          />
          {/* <div className="sendingEmailBlockInteractionText">{loadingStatus}</div> */}
        </div>
      )}
      <div className="reportViewerTopBar">
        &nbsp;
        <Button
          variant="contained"
          onClick={() => returnToDashboard()}
          // disabled={true}
          style={{
            position: "absolute",
            top: "40px",
            left: "25%",
            width: "200px",
            height: "40px",
            borderRadius: "8px",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "26px",
            letterSpacing: "0.02em",
            color: "white",
            backgroundColor: "#B40125",
            textTransform: "none",
          }}
        >
          {" "}
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => sendEmail()}
          style={{
            position: "absolute",
            top: "40px",
            left: "60%",
            width: "200px",
            height: "40px",
            borderRadius: "8px",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "26px",
            letterSpacing: "0.02em",
            color: "white",
            backgroundColor: "#39B54A",
            textTransform: "none",
          }}
        >
          {" "}
          Send report
        </Button>
      </div>
      <div className="reportViewerReportOuterContainer">
        <div ref={reportInViewerRef} className="reportViewerReportContainer">
          {isLoaded && (
            <Report
              advisor={adviser}
              sessionData={sessionData}
              reportIsLoaded={(sigLoaded) => reportIsLoaded(sigLoaded)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportViewer;
