import React, { useState } from "react";
import "./layout.css";

import CookieBanner from "../CookieBanner/CookieBanner";

function Layout({ children }) {
  const [cookiePreferences, setCookiePreferences] = useState(
    document.cookie.split(";").some((item) => item.trim().startsWith("_acpt="))
  );

  const closeDialog = (event) => {
    event.preventDefault();
    setCookiePreferences(true);
  };

  return (
    <div className="layoutRoot">
      {cookiePreferences === false && (
        <CookieBanner closeDialog={(event) => closeDialog(event)} />
      )}
      {children}
    </div>
  );
}

export default Layout;
