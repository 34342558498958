import React from "react";
import FideliusBannerPNG from "./FideliusBanner.png";

function FideliusBanner({}) {
  return (
    <svg
      width="480"
      height="150"
      viewBox="0 0 480 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="480" height="150" fill="url(#pattern0)" />
      <image width="480" height="150" xlinkHref={FideliusBannerPNG} />
    </svg>
  );
}

export default FideliusBanner;
