import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "@firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
  authDomain: "tpo-ammonite-16f32.firebaseapp.com",
  projectId: "tpo-ammonite-16f32",
  storageBucket: "tpo-ammonite-16f32.appspot.com",
  messagingSenderId: "713381117236",
  appId: "1:713381117236:web:2a4ea2048ff885395bf961",
  measurementId: "G-4TNGCPDXZ2",
};

const app = firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === 'localhost') {
//   app.firestore().settings({
//     host: 'localhost:8081',
//     ssl: false,
//   })
// }

export default firebase;
